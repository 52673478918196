import { earlyRequestUserAttributes } from 'frontend-preferences-client/quickFetch';
import { treatmentsPromise } from 'dashboard-ui-components/js/data/laboratoryClient';
const DASHBOARDS_ENDPOINT = `/dashboard/v2`;
const DASHBOARDS_URL = `${DASHBOARDS_ENDPOINT}/dashboard?hydrate=USER_PERMISSION_LEVEL`;
const REPORT_COUNTS_URL = `${DASHBOARDS_ENDPOINT}/reports/report-counts`;
const CUSTOM_REPORTING_PROPERTIES_URL = `/sql-reporting/v1/extended-reportable-options/data-sources/properties`;
const BASIC_REPORTING_PROPERTIES_URL = `/properties/v4`;
const INCLUDE_FLP_QUERY_PARAM = `?includeFieldLevelPermission=true`;
const IMPROVED_DB_FILTER_OPTIN_NAME = 'reporting-improved-db-filter-v1-optin';
const IMPROVED_DB_FILTER_OPTIN_URL = `/optins/v1/availability/${IMPROVED_DB_FILTER_OPTIN_NAME}`;
export const quickFetchInstance = window.quickFetch;
const portalId = quickFetchInstance.getPortalId();

// Unlike hub-http, quick-fetch does not have built in URL query parsing
// so the query params must be manually added to the URL
const USER_ATTRIBUTES_KEYS = {
  PREFERRED_HOME: `preferred-home:portal-${portalId}`,
  DB_FILTERS_SUNSET_ALERT_DISMISSED_TIMESTAMP_KEY: 'Reporting:DashboardFiltersSunsetAlertDismissedTimestamp'
};
const ATTRIBUTE_KEYS = Object.values(USER_ATTRIBUTES_KEYS);
const MAX_TIMEOUT = 50000;
const baseConfig = {
  timeout: MAX_TIMEOUT,
  dataType: 'json',
  contentType: 'application/json',
  type: 'GET'
};

// Grab dashboard ID from URL to use for get request for current dashboard data.
const matches = window.location.pathname.match(/view?\/(\d+)/);
const DASHBOARD_ID = matches && matches.length ? matches[1] : null;
const DASHBOARD_URL = `${DASHBOARDS_ENDPOINT}/dashboard/${DASHBOARD_ID}`;
earlyRequestUserAttributes(ATTRIBUTE_KEYS, 'userAttributes');
quickFetchInstance.makeEarlyRequest('dashboards', Object.assign({
  url: quickFetchInstance.getApiUrl(DASHBOARDS_URL)
}, baseConfig));
quickFetchInstance.makeEarlyRequest('reportCounts', Object.assign({
  url: quickFetchInstance.getApiUrl(REPORT_COUNTS_URL)
}, baseConfig));
if (DASHBOARD_ID) {
  quickFetchInstance.makeEarlyRequest('dashboard', Object.assign({
    url: quickFetchInstance.getApiUrl(DASHBOARD_URL)
  }, baseConfig));
}
quickFetchInstance.makeEarlyRequest(`optin-${IMPROVED_DB_FILTER_OPTIN_NAME}`, Object.assign({
  url: quickFetchInstance.getApiUrl(IMPROVED_DB_FILTER_OPTIN_URL),
  whenFinished: availability => {
    availability.optinView = availability.state === 'OPTED_IN' ? 'NEW' : 'CURRENT';
  }
}, baseConfig));
quickFetchInstance.afterAuth(auth => {
  if (!auth) {
    return;
  }
  const scopes = auth.user.scopes;
  const hasCustomReportingAccess = scopes.includes('custom-reporting-access');
  const dataSourcePropertiesBaseUrl = hasCustomReportingAccess ? CUSTOM_REPORTING_PROPERTIES_URL : BASIC_REPORTING_PROPERTIES_URL;
  try {
    const localStorageFilters = JSON.parse(localStorage.getItem('Reporting:DashboardFilters') // should match STORAGE_KEY in persistedDashboardFilters.js
    ) || {};
    const portalFilters = localStorageFilters[`portal-${portalId}`] || {};
    const dashboardFilters = portalFilters[DASHBOARD_ID] || [];
    const frameworkDataSources = dashboardFilters.map(filter => filter.dataSource).filter(dataSource => /\d+-\d+/.test(dataSource));
    const uniqueDataSourceKeys = new Set(frameworkDataSources);
    for (const dataSourceId of Array.from(uniqueDataSourceKeys)) {
      const dataSourcePropertiesFinalUrl = `${dataSourcePropertiesBaseUrl}/${dataSourceId}/${!hasCustomReportingAccess && INCLUDE_FLP_QUERY_PARAM}`;
      quickFetchInstance.makeEarlyRequest(`properties-${dataSourceId}`, Object.assign({
        url: quickFetchInstance.getApiUrl(dataSourcePropertiesFinalUrl)
      }, baseConfig));
    }
  } catch (error) {
    console.error(error);
  }
});
void treatmentsPromise;