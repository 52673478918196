import { createClient } from 'laboratory-lib';
import PortalIdParser from 'PortalIdParser';
import experiments from '../../experiments.yaml';
export const laboratoryClient = createClient({
  quickFetchLabel: 'experiment-treatments',
  experiments: experiments,
  identifiers: {
    portalId: PortalIdParser.get()
  }
});
export const treatmentsPromise = new Promise(laboratoryClient.resolve);