module.exports = {
  "onbu-0039": {
    "identifierType": "PORTAL_ID",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variant"
      ]
    }
  },
  "res-0010": {
    "identifierType": "PORTAL_ID",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  }
};